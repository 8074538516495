<template>
  <div v-if="table_permissions" class="custom-content-height">
    <v-data-table
      fixed-header
      light
      dense
      :height="getvh(56)"
      :items="items"
      :headers="headers"
      :items-per-page="itemPerPage"
      item-key="id"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE!</template>
      <template v-slot:no-results> NO RESULTS HERE!</template>
      <!--    no-data end-->

      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-1"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>

          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('create')"
            >
              <button
                @click.prevent="showAddItemModal"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-plus-box-outline</v-icon>
                </span>
                Add {{ table_properties.title }}
              </button>
              <AddItem
                :pageLoader="pageLoad"
                :refresher="updateTableContent"
                ref="addItem"
              ></AddItem>
            </div>
            <UploadSelect
              v-if="permissionChecker('upload')"
              :types="$store.getters.getASNUploadType"
              :actionFunction="showUploadModal"
              :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
            ></UploadSelect>
            <!--begin::Dropdown-->

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('export')"
            >
              <button
                @click="$refs.exportSidebar.$data.exportSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                Export
              </button>
            </div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="$refs.filterSidebar.$data.filterSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>

            <!--end::Button-->
          </div>
        </div>

        <ExportSidebar
          v-if="exportHeaders.length > 0"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
      </template>

      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-for="(action, index) in actions" :key="index">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions[action.slug]"
                :class="
                  !item.extra_data.detail.actions[action.slug]
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="updateStatus(item, action)"
                text
              >
                {{ action.label }}
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
  </div>
</template>

<script>
import {
  UPDATE_TABLE_DATA,
  SET_FILTERS_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  EXPORT_TABLE_DATA,
} from "@/core/services/store/skippedFulfillmentTasks.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar";
import Pagination from "@/own/components/pagination/Pagination.vue";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ApiService from "@/core/services/api.service";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";
import UploadSelect from "@/own/components/UploadSelect.vue";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: {
    DatatableDetail,
    FilterSidebar,
    Pagination,
    ComponentSelector,
    UploadSelect,
    ExportSidebar,
  },
  data: () => ({
    toolBarVisionState: false,
    actions: [
      {
        label: "Release task",
        slug: "release_task",
        permission: "release_task",
        message: "Task released",
      },
      {
        label: "Move to missing",
        slug: "move_to_missing",
        permission: "move_to_missing",
        message: "Task moved to missing location",
      },
      {
        label: "Unlock location",
        slug: "unlock_location",
        permission: "unlock_location",
        message: "Location unlocked",
      },
      {
        label: "De-allocate",
        slug: "de_allocate",
        permission: "de_allocate",
        message: "Task Deacllocated",
      },
    ],
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    exportHeaders: function () {
      let headers = null;
      try {
        headers =
          this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTablePermissions.filter(
            (col) => col.name === "export"
          )[0].columns;
      } catch (error) {
        headers = [];
      }
      return headers;
    },
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    //pagination
    getitemPerPage: function () {
      return this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableState.per_page;
    },
    getpageNumber: function () {
      return this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableData.last_page;
    },
    //filters
    filters: function () {
      let filters = null;
      try {
        filters = this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableFilters;
      } catch {
        filters = [];
      }
      return filters;
    },
    table_permissions: function () {
      if (this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTablePermissions) {
        return this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTablePermissions.map(
          function (perm) {
            return perm.name;
          }
        );
      } else {
        return false;
      }
    },
    table_properties: function () {
      return this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableProperties;
    },
    itemPerPage: function () {
      return this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableState.per_page;
    },
    headers: function () {
      let headers =
        this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableHeaders.filter(
          (col) => col.visible === true
        );
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    items: function () {
      if (this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableData) {
        return this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableData.data;
      }
      return [];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    downloadItem(columns) {
      if (!columns.length) {
        this.$refs.exportSidebar.$data.exportSidebar = false;
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      } else {
        this.$store.commit(SET_PAGE_LOADING, true);
        this.export_modal = false;
        this.$store
          .dispatch(EXPORT_TABLE_DATA, {
            columns: columns,
            warehouse_id: this.selectedWarehouse,
            filters:
              this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableState.filters,
          })
          .then(() => {
            if (this.$store.getters.getSKIPPEDFULFILLMENTTASKSSExportUrl) {
              const link = document.createElement("a");
              link.href =
                this.$store.getters.getSKIPPEDFULFILLMENTTASKSSExportUrl;
              link.download = "Export.csv";
              link.click();
            }
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.exportSidebar.$refs.export.toggleModal();
          });
      }
    },
    updateStatus(item, action) {
      this.pageLoad(true);
      ApiService.post(
        `/warehouse_management/skipped_fulfillment_tasks/${action.slug}`,
        {
          id: item.id,
        }
      )
        .then(() => {
          Swal.fire("Success!", action.message, "success");
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    // pagination
    setitemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setpageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    showUploadModal(type) {
      this.$router.push({
        name: "settings.uploads",
        query: { type: type },
      });
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableState,
      };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...state,
          warehouse_id: this.selectedWarehouse,
        })
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch((res) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          Swal.fire({
            title: "",
            text: `${res}`,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
      this.$store.commit(SET_FILTERS_DATA, this.men);
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.men = [];
      this.queryObj = {};
      this.$refs.filterSidebar.$refs.filter.clearFiltersState();
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableState,
          warehouse_id: this.selectedWarehouse,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    // filters end
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      this.pageLoad(true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableState,
          warehouse_id: this.selectedWarehouse,
        })
        .then(() => {});
      this.$forceUpdate();
      this.pageLoad(false);
    },
  },
};
</script>
